<template>
  <div class="newspaper-button-group">
    <div>
      <a-button @click="()=> $router.push({name:'home'})">新增报馆</a-button>
      <!-- <a-button danger ghost @click="delOrderList">删 除</a-button> -->
    </div>
    <div>
      <a-select
        ref="select"
        v-model:value="searchObj.examine"
        style="width: 140px"
        @change="handleChange(true,()=>{})"
      >
        <a-select-option value="">未选择</a-select-option>
        <a-select-option value="0">审核中</a-select-option>
        <a-select-option value="2">未通过</a-select-option>
        <a-select-option value="1">已通过</a-select-option>
      </a-select>
      <a-input-search
        class="newspaper-search"
        v-model:value="searchObj.corp"
        placeholder="公司名查询"
        enter-button="查询"
        @search="onSearch"
      />
    </div>
  </div>
  <a-table :columns="columns" :data-source="dataSource" bordered :pagination="pagination"  :row-key="(r,i)=>r.id" :scroll="{ x: 1500 }" >
    <template #abc="{clearFilters}">
      <div class="projectListOptions">
        <a-radio-group v-model:value="searchObj.pid">
          <template v-for="(item,i) in projectListOptions" :key="`projectChecked${i}`">
          <a-radio  :value="item.pid">{{item.p_name}}</a-radio><br/>
          </template>
        </a-radio-group>
        <div class="btn">
          <span @click="handleChange(true,clearFilters)">确定</span>
          <span @click="handleChange(false,clearFilters)">重置</span>
        </div>
      </div>
    </template>
    <template #examine="{ record }">
      <template v-if="record.examine*1 === 0">
      <span v-if="Math.abs(Date.parse(new Date()) - record.create_time) <= (30 * 60 * 1000)" class="state-orange">待审核</span>
      <span v-else class="state-orange">审核中</span>

      </template>
      <span v-else-if="record.examine*1 === 1" class="state-green">通过</span>
      <span v-else-if="record.examine*1 === 2" class="state-red">未通过</span>
    </template>
    <template #examine_cost="{ record }">
      {{ record.examine*1 !== 1 ? '-' : record.examine_cost*1 === 0 ? "待付款" : '已付款'}}
    </template>
    <template #examine_yj="{ record }">
      {{ record.examine*1 !== 1 ? '-' : record.examine_yj*1 === 0 ? "待付款" : '已付款' }}
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <!-- <a-button size="small" type="primary" @click="amendProject({id: record.id, pid: record.pid, flag:1, create_time:record.create_time})" ghost>查看</a-button> -->
        <details-preview :ids="{id: record.id, pid: record.pid}"/>
        <a-button style="margin-left: 8px;" v-if="modify(record)" type="primary" size="small" @click="amendProject({id: record.id, pid: record.pid, flag: 0, create_time:record.create_time, examine: record.examine })" danger >修改</a-button>
        <print-preview  v-if="record.examine*1 === 1" :ids="{id:record.id,pid:record.pid}" />
      </div>
    </template>
  </a-table>
</template>
<script>
import { message } from 'ant-design-vue'
import { defineComponent, onMounted, ref, computed, getCurrentInstance } from 'vue'
import { listOrder, listProjectNames } from '../../api'
import { PrintPreview, DetailsPreview } from './template'

export default defineComponent({
  components: {
    PrintPreview,
    DetailsPreview
  },
  setup () {
    const { proxy } = getCurrentInstance()
    const dataSource = ref([])
    let selectKeys = []
    const projectListOptions = ref([])
    const columns = [
      {
        title: '项目名称',
        dataIndex: 'p_name',
        align: 'center',
        width: 160,
        fixed: 'left',
        slots: {
          filterDropdown: 'abc',
          filterIcon: 'filterIcon',
          customRender: 'customRender'
        }
      },
      {
        title: '参展商名称',
        dataIndex: 'corp',
        align: 'center',
        width: 200,
        fixed: 'left',
        slots: {
          customRender: 'corp'
        }
      },
      {
        title: '展位号',
        dataIndex: 'booth_name',
        align: 'center',
        width: 120,
        slots: {
          customRender: 'booth_name'
        }
      },
      {
        title: '搭建商联系人',
        dataIndex: 'build_contacts',
        align: 'center',
        width: 120,
        slots: {
          customRender: 'build_contacts'
        }
      },
      {
        title: '搭建商手机号',
        dataIndex: 'build_contacts_mobile',
        align: 'center',
        width: 120,
        slots: {
          customRender: 'build_contacts_mobile'
        }
      },
      {
        title: '审核',
        dataIndex: 'examine',
        align: 'center',
        width: 120,
        slots: {
          customRender: 'examine'
        }
      },
      {
        title: '管理费',
        dataIndex: 'examine_cost',
        align: 'center',
        width: 120,
        slots: {
          customRender: 'examine_cost'
        }
      },
      {
        title: '押金',
        dataIndex: 'examine_yj',
        align: 'center',
        width: 120,
        slots: {
          customRender: 'examine_yj'
        }
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width: 160,
        fixed: 'right',
        slots: {
          customRender: 'operation'
        }
      }
    ]
    const page = ref({
      total: 0,
      pageSize: 10,
      current: 1
    })
    const searchObj = ref({
      corp: '',
      examine: '',
      pid: null
    })
    const pagination = computed(() => ({
      total: page.value.total,
      current: page.value.current,
      pageSize: page.value.pageSize,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      onShowSizeChange: pageSizeChange,
      onChange: pageChange
    }))
    const pageSizeChange = (pageNum, pageSize) => {
      requestList({ pageNum, pageSize, corp: searchObj.value.corp, pid: searchObj.value.pid, examine: searchObj.value.examine })
      page.value.pageSize = pageSize
    }
    const pageChange = (pageNum, pageSize) => {
      requestList({ pageNum, pageSize, corp: searchObj.value.corp, pid: searchObj.value.pid, examine: searchObj.value.examine })
    }
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        selectKeys = selectedRowKeys
      },
      onSelect: (record, selected, selectedRows) => {
        console.log('onSelect', record, selected, selectedRows)
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        console.log('onSelectAll', selected, selectedRows, changeRows)
      }
    }
    const modify = (record) => {
      const currentTime = Date.parse(new Date())
      if (record.examine * 1 === 2) {
        return true
      } else if (record.examine * 1 === 0) {
        return Math.abs(currentTime - record.create_time) <= (30 * 60 * 1000)
      } else {
        return false
      }
    }
    const delOrderList = async() => {
      return null
      // let arr = []
      // selectKeys.forEach(item => {
      //   arr.push({id:item})
      // });
      // const res = await delOrder(arr)
      // requestList({pageNum: page.value.current, pageSize: page.value.pageSize, corp: searchObj.value.corp, pid: searchObj.value.pid, examine: searchObj.value.examine })
    }

    // 修改项目
    const amendProject = ({ id, pid, flag, preCreateTime, examine }) => {
      const currentTime = Date.parse(new Date())
      if (Math.abs(currentTime - preCreateTime) >= (30 * 60 * 1000) && flag * 1 === 0 && examine * 1 === 0) {
        message.warn('审核中，无法修改！')
        setTimeout(() => {
          proxy.$root.$router.go(0)
        }, 1000)
        return
      }
      window.sessionStorage.setItem('project_id', pid)
      window.sessionStorage.setItem('id', id)
      proxy.$root.$router.push({ name: 'newNewspaper', params: { id, pid, flag } })
    }

    const onSearch = (content) => {
      requestList({ pageNum: page.value.current, pageSize: page.value.pageSize, corp: searchObj.value.corp, pid: searchObj.value.pid, examine: searchObj.value.examine })
    }

    const requestList = async({ pageSize, pageNum, corp, pid, examine }) => {
      const userID = window.sessionStorage.getItem('user-id')
      const data = {
        user_id: userID,
        pageNum: pageNum || 1,
        pageSize: pageSize || 10,
        corp: corp || '',
        pid: pid || '',
        examine: examine || ''
      }
      const res = await listOrder(data)
      if (res.code * 1 === 200) {
        // console.log('res', res)
        dataSource.value = res.list.list
        page.value.pageSize = res.list.pageSize
        page.value.total = res.list.total
        page.value.current = res.list.pageNum
      }
    }
    const initProjectNameListFun = async(userID) => {
      const res = await listProjectNames(userID)
      if (res.code * 1 === 200) {
        projectListOptions.value = res.projectList
      }
    }
    const handleChange = (flag, clearFilters) => {
      if (!flag) {
        searchObj.value.pid = null
        requestList({ pageNum: page.value.current, pageSize: page.value.pageSize, corp: searchObj.value.corp, pid: searchObj.value.pid, examine: searchObj.value.examine })
        clearFilters()
        return
      }
      requestList({ pageNum: page.value.current, pageSize: page.value.pageSize, corp: searchObj.value.corp, pid: searchObj.value.pid, examine: searchObj.value.examine })
      clearFilters()
    }
    onMounted(() => {
      window.sessionStorage.removeItem('project_id')
      window.sessionStorage.removeItem('projectFlag')
      window.sessionStorage.removeItem('id')
      window.sessionStorage.removeItem('etime')
      window.sessionStorage.removeItem('create_time')
      window.sessionStorage.removeItem('overdue')
      window.sessionStorage.removeItem('overdue_proportion')
      const userID = window.sessionStorage.getItem('user-id')
      requestList({})
      initProjectNameListFun(userID)
    })

    return {
      dataSource,
      pagination,
      columns,
      editingKey: '',
      amendProject,
      rowSelection,
      modify,
      delOrderList,
      onSearch,
      handleChange,
      projectListOptions,
      searchObj
    }
  }
})
</script>
<style scoped lang="less">
// .editable-row-operations button {
//   margin-right: 8px;
// }
.newspaper-button-group{
  display:flex;
  justify-content:space-between;
  padding-bottom:30px;
}
.newspaper-search{
  width: 400px;
}
.newspaper-button-group div > .ant-btn + .ant-btn{
  margin-left:20px;
}
.ant-select{
  margin-right:20px;
}

.projectListOptions{
  // padding: 10px;
  min-width: 120px;
}
.projectListOptions >div{
  padding: 10px;
}
.projectListOptions .btn{
  border-top: 1px dashed rgb(241, 241, 241);
  padding-top: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.projectListOptions .btn span{
  cursor: pointer;
  color: @primary-color;
}
</style>
